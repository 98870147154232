<template>
    <div>
        <van-form v-model="submitForm" ref="submitForm" @submit="submitSave">
            <van-field
                v-model="submitForm.oldPassword"
                type="password"
                label="原密码"
                placeholder="原密码"
                :rules="[{ required: true, message: '请填写原密码' }]"/>
            <van-field
                v-model="submitForm.newPassword"
                type="password"
                label="新密码"
                placeholder="新密码"
                :rules="[{ required: true, message: '请填写新密码' }]"/>
            <van-field
                v-model="submitForm.resPassword"
                type="password"
                label="确认密码"
                placeholder="确认密码"
                :rules="[{ required: true, message: '请填写确认密码' }]"/>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submitSave">提交</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import { updateUserPwd } from '@/api/user'
import { Toast } from 'vant';
export default {
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            submitForm:{
                oldPassword:'',
                newPassword:'',
                resPassword:''
            }
        };
    },
    methods: {
        submitSave(){
            if(!this.submitForm.oldPassword){
                this.$toast.fail('原密码不能为空');
                return false;
            }
            if(!this.submitForm.newPassword){
                this.$toast.fail('新密码不能为空');
                return false;
            }
            if(this.submitForm.newPassword != this.submitForm.resPassword){
                this.$toast.fail('两次密码输入不一至');
                return false;
            }
            // this.$refs["submitForm"].validate(valid => {
            //     if (valid) {
                    updateUserPwd(this.submitForm).then(response => {
                        this.$toast.success('修改成功');
                    });
            //     }
            // });
        }
    },
};
</script>
